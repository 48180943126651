<template>
    <footer class="absolute bottom-0 w-screen mt-10">
        <!-- Top -->
        <section class="py-4 bg-gray-950">
            <div class="flex py-12 lg:mr-20">
                <img src="@/assets/img/ptm.png" alt="Platinum Tags Media Logo" class="w-56 mx-auto">
            </div>
            <div class="hidden justify-between text-gray-600 px-4 md:px-4 lg:pr-32 lg:pl-12 xl:pr-64 xl:pl-48 3xl:pr-96 3xl:pl-72 mx-auto pb-12">
                <div>
                    <h3 class="font-bold text-gray-800 py-4 text-center">Company</h3>
                    <div class="space-y-3">
                        <a href="/#about-us" class="block hover:text-black ">
                            <p class="flex">
                                <span class="my-auto">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        class="h-5 w-5" 
                                        viewBox="0 0 20 20" 
                                        fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                                <span class="my-auto">
                                    About Us
                                </span>
                            </p>
                        </a>
                        <router-link to="/work" class="block hover:text-black ">
                            <p class="flex">
                                <span class="my-auto">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        class="h-5 w-5" 
                                        viewBox="0 0 20 20" 
                                        fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                                <span class="my-auto">
                                    Our Work
                                </span>
                            </p>
                        </router-link>
                        <router-link to="/services" class="block hover:text-black ">
                            <p class="flex">
                                <span class="my-auto">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        class="h-5 w-5" 
                                        viewBox="0 0 20 20" 
                                        fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                                <span class="my-auto">
                                    Services
                                </span>
                            </p>
                        </router-link>
                        <router-link to="/services" class="block hover:text-black ">
                            <p class="flex">
                                <span class="my-auto">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        class="h-5 w-5" 
                                        viewBox="0 0 20 20" 
                                        fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                                <span class="my-auto">
                                    Movies
                                </span>
                            </p>
                        </router-link>
                    </div>
                    
                </div>
                <div class="hidden">
                    <h3 class="font-bold text-gray-800 py-4 text-center">Helpful Links</h3>
                    <div class="space-y-3">
                        <a to="/#contact-us" class="block hover:text-black ">
                            <p class="flex">
                                <span class="my-auto">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        class="h-5 w-5" 
                                        viewBox="0 0 20 20" 
                                        fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                                <span class="my-auto">
                                    Contact
                                </span>
                            </p>
                        </a>
                        <router-link to="/services" class="block hover:text-black ">
                            <p class="flex">
                                <span class="my-auto">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        class="h-5 w-5" 
                                        viewBox="0 0 20 20" 
                                        fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                                <span class="my-auto">
                                    Artists
                                </span>
                            </p>
                        </router-link>
                        <router-link to="/services" class="block hover:text-black ">
                            <p class="flex">
                                <span class="my-auto">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        class="h-5 w-5" 
                                        viewBox="0 0 20 20" 
                                        fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                                <span class="my-auto">
                                    Social Feeds
                                </span>
                            </p>
                        </router-link>
                    </div>
                    
                </div>
                <div class="hidden">
                    <h3 class="font-bold text-gray-800 py-4 pl-3">Legal Stuff</h3>
                    <div class="space-y-3">
                        <router-link to="/" class="block hover:text-black ">
                            <p class="flex">
                                <span class="my-auto">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        class="h-5 w-5" 
                                        viewBox="0 0 20 20" 
                                        fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                                <span class="my-auto">
                                    Terms of Service
                                </span>
                            </p>
                        </router-link>
                        <router-link to="/work" class="block hover:text-black ">
                            <p class="flex">
                                <span class="my-auto">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        class="h-5 w-5" 
                                        viewBox="0 0 20 20" 
                                        fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                                <span class="my-auto">
                                    Privacy Policy
                                </span>
                            </p>
                        </router-link>
                        <router-link to="/services" class="block hover:text-black ">
                            <p class="flex">
                                <span class="my-auto">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        class="h-5 w-5" 
                                        viewBox="0 0 20 20" 
                                        fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                                <span class="my-auto">
                                    Copyright Notice
                                </span>
                            </p>
                        </router-link>
                        <router-link to="/services" class="block hover:text-black ">
                            <p class="flex">
                                <span class="my-auto">
                                    <svg 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        class="h-5 w-5" 
                                        viewBox="0 0 20 20" 
                                        fill="currentColor">
                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                                <span class="my-auto">
                                    Cancellation Policy
                                </span>
                            </p>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
        <!-- Bottom -->
        <section class="bg-black py-6">
            <div class="text-center md:flex justify-between text-gray-500 text-xs px-4 md:px-4 lg:pr-32 lg:pl-12 xl:pr-64 xl:pl-48 3xl:pr-96 3xl:pl-72 mx-auto">
                <p class="uppercase font-medium">Copyright &copy; 2021 PlatinumTags Media, LLC</p>
                <p class="uppercase space-x-2 font-medium">
                    <span>
                        Made With
                    </span>
                     
                    <span class="text-red-600 my-auto">
                        <i class="fas fa-heart"></i>
                    </span> 
                    <span class="text-lg">+</span>
                    <span class="my-auto text-yellow-900">
                        <i class="fas fa-mug-hot"></i>
                    </span>

                    <span >
                        By <a 
                            href="https://github.com/freinet12" 
                            target="_blank" 
                            class="pl-1 hover:text-gray-400">Freinet Brutus
                            </a>
                    </span>
                </p>
            </div>
        </section>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>

</style>